import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import profilepic from '../img/MyFace.png';
import Social from '../components/Social'

const Home = () => {
  return (
    <div className="condiv home">
      <img src={profilepic} className="profilepic" alt="my face"></img>

      <ReactTypingEffect className="typingeffect" text={['Hello! I am Chris Mercer', 'I am an experienced developer and manager']} speed={50} eraseDelay={400} />

      <Social />
    </div>
  );
}

export default Home;