import React from 'react';

import point from '../img/star.png'

const Scores = (props) => {
  const items = []

  for(let i = 0; i < props.score; i++) {
    items.push(<img src={point} alt="one point" className="pointimage" key={i}></img>)
  }

  return (
    <div>
      { items }
    </div>
  )
}

export default Scores;
