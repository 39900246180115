import React from 'react';

const Social = () => {
  return (
    <div className="social">
      <a href="https://github.com/chriswmercer" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a>
      <a href="https://www.linkedin.com/in/christopher-mercer-61619172/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
    </div>
  )
}

export default Social;