import React, { useState } from 'react';
import NavItem from './NavItem';
import logo from '../img/chrispng_blackborder.png';

const NavBar = () => {
    const [NavItemActive, SetNavItemActive] = useState(0);

    const setActiveItem = (selectedItem) => {
      SetNavItemActive({'named':selectedItem});
    };

    return (
      <nav>
        <div className="logodiv">
          <img src={logo} alt="A logo"></img>
        </div>
        <ul>
          <NavItem item="Home" toLink="/" clickHandler={setActiveItem} active={NavItemActive.named}></NavItem>
          <NavItem item="Education" toLink="/Learn" clickHandler={setActiveItem} active={NavItemActive.named}></NavItem>
          <NavItem item="Professional" toLink="/Work" clickHandler={setActiveItem} active={NavItemActive.named}></NavItem>
          <NavItem item="Interests" toLink="/Play" clickHandler={setActiveItem} active={NavItemActive.named}></NavItem>
          <NavItem item="Contact" toLink="/contact" clickHandler={setActiveItem} active={NavItemActive.named}></NavItem>
        </ul>
      </nav>
    )
}

export default NavBar;