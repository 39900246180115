import React from 'react'
import Widecard from '../components/Widecard';
import Skills from '../components/Skills';

import goss from '../img/goss.png';
import hmsraleigh from '../img/royalnavy.png';
import itim from '../img/itim.jpeg';
import fdb from '../img/fdb.jpeg';
import vualto from '../img/vualto.png';
import wb from '../img/webbased.png';
import acer from '../img/acer.png';
import bcs from '../img/bcs.png';
import stem from '../img/stem.png';
import plym from '../img/plym.png';
import ex from '../img/exercism.png';
import ksc from '../img/ksc.png';


const Work = () => {

  const skills = { 'C#' : 5,'Web Development (ASP.NET MVC, Backend)' : 5, "Azure" : 4,  "Git" : 3, "AWS": 3, 'SQL' : 3, 'JavaScript (general)' : 3, 'HTML/CSS/JS/JQuery' : 3,  'Python' : 3, "C++" : 3, "Principles of Machine Learning" : 2, "Azure Cognitive Services" : 2, 'React' : 2, 'Ruby' : 2, 'Java' : 2, "Rust" : 2,"Swift" : 2, "Kotlin" : 2, "iOS Development" : 2, "Android Development" : 2, "GO" : 2, "PHP" : 2, "Linux" : 2, "Clojure" : 1};

  return (
      <div className="condiv">
        <h1 className="subtopic">Selected Past Roles</h1>
        <Widecard title="AI Technical Lead" where="Körber Supply Chain Software" from="August 2022" to="Present" imageName={ksc}/>
        <Widecard title="Software Team Leader" where="First Databank" from="December 2018" to="July 2022" imageName={fdb}/>
        <Widecard title="Senior Developer / Project Technical Lead" where="Vualto" from="April 2017" to="December 2018" imageName={vualto}/>
        <Widecard title="Lead Developer" where="WebBased LTD" from="April 2016" to="May 2017" imageName={wb} />
        <Widecard title="C# Developer" where="Goss Interactive" from="May 2013" to="March 2016" imageName={goss}/>
        <Widecard title="Developer" where="ITIM" from="June 2008" to="May 2013" imageName={itim}/>
        <Widecard title="Support Technician" where="Royal Navy" from="June 2006" to="June 2008" imageName={hmsraleigh}/>
        <Widecard title="Senior Support Specialist" where="Acer" from="July 2004" to="July 2006" imageName={acer}/>
        <hr />
        <h1 className="subtopic">Volunteering</h1>
        <Widecard title="Chartered Assessor" where="British Computer Society" from="March 2019" to="Present" imageName={bcs}/>
        <Widecard title="Ambassador" where="STEM Ambassadors" from="September 2018" to="Present" imageName={stem}/>
        <Widecard title="Student Mentor" where="Plymouth University" from="August 2018" to="Present" imageName={plym}/>
        <Widecard title="Mentor" where="Exercism" from="Janunary 2019" to="Present" imageName={ex}/>
        <hr />
        <h1 className="subtopic">Selected Technical Skills</h1>
        <div className="margintop">
          <Skills skills={skills} />
        </div>
     </div>
  )
}

export default Work;