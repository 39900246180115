import React from 'react'

const Widecard = (props) => {

  const nopic = (!props.imageName);
  const nourl = (!props.url);
  const urldesc = (props.urldesc ? props.urldesc : "Click Here")
  const bigpic = (props.bigpic);

  return (
    <div className="widecard">
      { nopic ? "" :
        <div className="cardimage">
          <img src={props.imageName} alt={props.where}></img>
        </div>
      }
      <div className="compdet">
        <h3>{props.title}</h3>
        { !props.where ? "" :
          <h4 className="secondtext">{props.where}</h4>
        }
        <h4 className="secondtext">{(props.from && props.from !== "-" && props.from !== "") ? props.from : ""} {(props.to && props.to !== "-" && props.to !== "") ? ("- " + props.to) : ""}</h4>
        {
          nourl ? "" :
          <a href={props.url} alt={props.title}><h4 className="secondtext">{urldesc}</h4></a>
        }
        {
          !bigpic ? "" :
          <img clasName="wideimage" src={props.bigpic} alt={props.alt}></img>
        }
      </div>
    </div>
  )
}

export default Widecard;