import React from 'react';
import Widecard from '../components/Widecard';

const Contact = () => {
  return (
    <div className="condiv">
      <h1 className="subtopic">Contact</h1>
      <Widecard title="LinkedIn" url="https://www.linkedin.com/in/christopher-mercer-61619172/" />
      <Widecard title="Email" url="mailto:me@chriswm.co.uk" urldesc="me@chriswm.co.uk"/>
    </div>
  );
}

export default Contact;