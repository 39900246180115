import React from 'react';
import Widecard from '../components/Widecard';
import tkd from '../img//tkdbelts.JPG'

const Play = ()=> {
  return (
    <div className="condiv">
      <h1 className="subtopic">Selected Interests</h1>
      <Widecard title="Reading" where="Mostly Scifi and Fantasy" />
      <Widecard title="Tae Kwon Do" where="Tae Kwon Do Association of Great Britain - 1st Dan Black Belt" bigpic={tkd} alt="Tae Kwon Do Belts"/>
      <Widecard title="Guitar" where="Self-taught, but aiming to take grade 5 this year, My main guitar is a Gibson Les Paul Standard from 2002." />
      <Widecard title="Role playing games" where="Such as World of Darkness, D&amp;D, L5R, Savage Worlds, various LARPs. I used to help run and Ref the LARP Blood Red Roses, based in Exeter in the UK." />
      <Widecard title="Languages" where="I speak French to CEFR A2 level (getting rusty as time goes on). I speak German to slightly less than A1 standard, and Russian to a very basic standard." />
      <Widecard title="Home Automation" where="Amazon Alexa, including custom skill building." />
      <hr />
      <h1 className="subtopic">Pets (as of May 2020)</h1>
      <Widecard title="Sal the corn snake" where="Sal is a 2 years old anerythristic (black albino) corn snake. She enjoys eating mice and exploring her enclosure and is very tame." />
      <Widecard title="Bertie the syrian hamster" where="Bertie is a 17 month old white and brown syrian hamster. He is very tame and enjoys cuddles and carrot." />
      <Widecard title="Beatrix the russian dwarf hamster" where="Beatrix is a 12 months old russian dwarf hamster. She is also very tame and enjoys running around and exploring." />
      <Widecard title="Billy and Patchy the red cap oranda gold fish" where="Billy is around 18 months old, and Patchy (so called because of his big black eye patches) only 6 months old. They enjoy exploring their tank and guilting me into over-feeding them." />
    </div>
  );
}

export default Play;