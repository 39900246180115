import React from 'react';
import './App.css';
import
{ 
  HashRouter, 
  Route
} from "react-router-dom";

import NavBar from './components/NavBar';
import Home from './contents/Home';
import Education from './contents/Learn';
import Work from "./contents/Work";
import Play from './contents/Play';
import Contact from './contents/Contact';

const App = () => {
  return (
    <div>
      <HashRouter>
        <div className="App">
          <NavBar />
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/Learn">
            <Education />
          </Route>
          <Route path="/Work">
            <Work />
          </Route>
          <Route path="/Play">
            <Play />
          </Route>
          <Route path="/Contact">
            <Contact />
          </Route>
        </div>
      </HashRouter>
    </div>
  );
}

export default App;