import React from 'react'
import Skill from '../components/Skill';

const Skills = (props) => {
  const objSkills = props.skills;

  return (
    <div className="skillsdiv">
      {Object.keys(objSkills).map((skill) => {
        return <Skill skillName={skill} skillScore={objSkills[skill]} key={skill}/>
      })}
    </div>
 )
}

export default Skills;