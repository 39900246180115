import React from "react";
import { Link } from "react-router-dom";

const NavItem = (props) => {
  return (
    <li id={props.item} className={props.active === props.item ? "active" : ""}>
      <Link to={props.toLink} onClick={props.clickHandler.bind(this, props.item)}>
        {props.item}
      </Link>
    </li>
  )
}

export default NavItem

