import React from 'react'
import Widecard from '../components/Widecard';

import bcs from '../img/bcs.png';
import plym from '../img/plym.png';
import ec from '../img/ec.png';
import cppi from '../img/cppi.png';
import ms from '../img/ms.png';
import hbp from '../img/hbp.png';
import bywater from '../img/bywater.png';
import btec from '../img/btec.png';
import ncfe from '../img/ncfe.png';
import bd from '../img/bd.jpeg';
import edx from '../img/edx.png';
import udemy from '../img/udemy.jpeg';
import uoe from '../img/exe.png'
import tquk from '../img/tquk.png'
import pyin from '../img/pysmall.png'
import fwtv from '../img/fwtv.jpeg'
import lcfa from '../img/lfca.png'

const Education = () => {

  return (
      <div className="condiv">
        <h1 className="subtopic">Academic Qualifications</h1>
        <Widecard title="BSc (Hons) Computing and Informatics" where="Plymouth University" from="1997" to="2001" imageName={plym}/>
        <hr />
        <h1 className="subtopic">Professional Qualifications</h1>
        <Widecard title="European Engineer (EUR ING)" where="FEANI via the Engineering Council" from="September 2021" to="Present" imageName={ec}/>
        <Widecard title="Chartered Engineer (CEng)" where="Engineering Council" from="April 2019" to="Present" imageName={ec}/>
        <Widecard title="Chartered IT Professional (MBCS CITP)" where="British Computer Society" from="August 2018" to="Present" imageName={bcs}/>
        <Widecard title="Microsoft Certified Solutions Developer" where="Microsoft" from="November 2018" to="Present" imageName={ms}/>
        <Widecard title="C++ Certified Associate Programmer" where="C++ Institute" from="July 2020" to="Present" imageName={cppi}/>
        <Widecard title="PCAP – Certified Associate in Python Programming" where="Python Institute" from="July 2020" to="Present" imageName={pyin}/>
        <Widecard title="JavaScript Specialist 2020" where="Framework Television: Technically Speaking" from="August 2020" to="Present" imageName={fwtv}/>
        <Widecard title="Microsoft Certified Solutions Associate (SQL 2016 Database Development)" where="Microsoft" from="January 2021" to="Present" imageName={ms}/>
        <Widecard title="Linux Foundation Certified Associate" where="Linux Foundation" from="January 2021" to="Present" imageName={lcfa}/>
        <Widecard title="Microsoft Certified Azure Developer Associate" where="Microsoft" from="March 2021" to="Present" imageName={ms}/>
        <Widecard title="Microsoft Certified Trainer" where="Microsoft" from="April 2021" to="Present" imageName={ms}/>
        <Widecard title="Microsoft Certified Azure DevOps Engineer Expert" where="Microsoft" from="April 2021" to="Present" imageName={ms}/>
        <Widecard title="Microsoft Certified Azure AI Fundamentals" where="Microsoft" from="May 2021" to="Present" imageName={ms}/>
        <Widecard title="Microsoft Certified Azure Administrator Associate" where="Microsoft" from="June 2021" to="Present" imageName={ms}/>
        <hr />
        <h1 className="subtopic">Selected Training Courses</h1>
        <Widecard title="Leadership Development Program" where="Harvard Busiess Publishing" from="November 2019" to="March 2020" imageName={hbp}/>
        <Widecard title="IOS9001 Internal Auditor" where="Bywater" from="June 2019" to="June 2019" imageName={bywater}/>
        <Widecard title="Team Leading lvl 2" where="BTEC" from="May 2013" to="-" imageName={btec}/>
        <Widecard title="Diplôme d'études en langue française (CEFR A2)" where="Exeter University (exam center)" from="September 2019" to="-" imageName={uoe}/>
        <Widecard title="Working in the healthcare sector lvl 2" where="NCFE Cache" from="November 2019" to="-" imageName={ncfe}/>
        <Widecard title="Lean Organisation Management Techniques" where="NCFE" from="February 2020" to="-" imageName={ncfe}/>
        <Widecard title="Certificate in Understanding Autism lvl 2" where="TQUK" from="March 2020" to="-" imageName={tquk}/>
        <Widecard title="Certificate in Awareness of Mental Health Problems lvl 2" where="TQUK" from="September 2020" to="-" imageName={tquk}/>
        <Widecard title="Certificate in Event Planning lvl 2" where="TQUK" from="December 2020" to="-" imageName={tquk}/>
        <hr />
        <h1 className="subtopic">Online Training Completed (Highlights)</h1>
        <Widecard title="Android App Development" where="BitDegree" from="May 2020" to="-" imageName={bd}/>
        <Widecard title="Machine Learning Specialist Learning Pathway" where="BitDegree" from="April 2020" to="May 2020" imageName={bd}/>
        <Widecard title="iOS App Development" where="BitDegree" from="May 2020" to="-" imageName={bd}/>
        <Widecard title="Microsoft DAT215.1x - Developing SQL Databases" where="EDx" from="-" to="-" imageName={edx}/>
        <Widecard title="Various incluing Ruby, Docker, Rust, Go" where="Udemy" from="-" to="-" imageName={udemy}/>
     </div>
  )
}

export default Education;