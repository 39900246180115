import React from 'react'
import Scores from './Scores'

const Skill = (props) => {
  return (
    <div className="skill">
      <div>
        <div className="alignleft">{props.skillName}</div>
        <div className="alignright">
          <Scores score={props.skillScore} />
        </div>
      </div>
      <div className="clear"></div>
    </div>
  )
}

export default Skill;